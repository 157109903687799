// utils/updateAllContactStats.js

import axios from 'axios';
import axiosInstance from './axiosConfig';

const authToken = localStorage.getItem('authToken');

/**
 * Utility function to get today's date in YYYY-MM-DD format
 */
const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; // 'YYYY-MM-DD'
};

/**
 * Updates the contact statistics for all contacts in selectedMatchbox.
 *
 * @returns {Promise<void>}
 */


export const updateDisposition = async (id, newDisposition) => {
  const changes = {
    disposition: newDisposition
  };
  const apiUrl = 'https://pbj.digital/update-contact'; // e.g., 'https://yourapi.com/api/update-contact'

  const response = await axiosInstance.put(`${apiUrl}/${id}/`,changes, {
    headers: {
      'Content-Type': 'application/json',
      // No auth headers as per your requirement
    },
  });

  if (response.status === 200 || response.status === 204) {
    console.log(`Contact ${id} updated successfully:`, changes);
  } else {
    console.warn(`Unexpected response status for contact ${id}:`, response.status);
  }
  axiosInstance.put(`https://pbj.digital/dashboard/disposition`, {
    record_id: localStorage.getItem('lastReported'),
    disposition: newDisposition
  });
  console.log('lastReported', localStorage.getItem('lastReported'))
  
};



export const updateAllContactStats = async () => {
  console.log('updateAllContactStats called');
  try {
    // Retrieve the selectedMatchbox array from localStorage
    const selectedMatchboxJSON = localStorage.getItem('selectedMatchbox');
    if (!selectedMatchboxJSON) {
      console.warn('No selectedMatchbox found in localStorage.');
      return;
    }

    const selectedMatchbox = JSON.parse(selectedMatchboxJSON).items;

    if (!Array.isArray(selectedMatchbox) || selectedMatchbox.length !== 3) {
        console.log(selectedMatchbox)
      console.warn('selectedMatchbox is not a valid array or does not contain exactly 3 contacts.');
      return;
    }

    // Retrieve the answeredNumber from localStorage
    const answeredNumber = localStorage.getItem('answeredNumber');
    if (!answeredNumber) {
      console.warn('No answeredNumber found in localStorage.');
      // Proceed assuming no contact answered; all dispositions will be 'no answer'
    }

    // Determine if the call was answered
    const wasAnswered = answeredNumber !== null && answeredNumber !== undefined && answeredNumber !== '';

    // Prepare the API endpoint
    const apiUrl = 'https://pbj.digital/update-contact'; // e.g., 'https://yourapi.com/api/update-contact'

    if (!apiUrl) {
      console.error('API URL is not defined in environment variables.');
      return;
    }

    // Today's date in YYYY-MM-DD format
    const today = getTodayDate();

    // Function to update a single contact
    const updateContact = async (contact) => {
      const { id, kixie_phone_number, total_calls, last_called, disposition } = contact;

      if (!id) {
        console.warn('Contact is missing id:', contact);
        return contact; // Skip updating this contact
      }

      // Determine the new disposition
      let newDisposition = disposition; // Default to existing disposition

      if (wasAnswered && kixie_phone_number === answeredNumber) {
        newDisposition = 'answered';
      } else {
        newDisposition = 'no answer';
      }

      // Increment total_calls
      const updatedTotalCalls = (total_calls || 0) + 1;

      // Prepare the fields to update
      const changes = {
        total_calls: updatedTotalCalls,
        last_called: today,
        disposition: newDisposition
      };
      try {
        // Make the API request to update the contact
        const response = await axiosInstance.put(`${apiUrl}/${id}/`,changes, {
          headers: {
            'Content-Type': 'application/json',
            // No auth headers as per your requirement
          },
        });

        if (response.status === 200 || response.status === 204) {
          console.log(`Contact ${id} updated successfully:`, changes);
          return { ...contact, ...changes }; // Return updated contact
        } else {
          console.warn(`Unexpected response status for contact ${id}:`, response.status);
          return contact; // Return original contact if update failed
        }
      } catch (error) {
        console.error(`Error updating contact ${id}:`, error);
        // Optionally, implement retry logic or notify the user
        return contact; // Return original contact if error occurs
      }
    };

    // Update all contacts in parallel
    const updatePromises = selectedMatchbox.map((contact) => updateContact(contact));
    const updatedContacts = await Promise.all(updatePromises);

    // Update localStorage with the new contact data
    localStorage.setItem('selectedMatchbox', JSON.stringify(updatedContacts));

    console.log('All contacts updated and localStorage synchronized.');
  } catch (error) {
    console.error('Error in updateAllContactStats:', error);
    // Optionally, implement retry logic or notify the user
  }
};