import { Card, Col, Layout, Row, Space, Statistic, Table, DatePicker, Select, Spin, Button, message } from "antd";
import { Header } from "antd/es/layout/layout";
import Logo from "./logo";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "./axiosConfig";
import { AuthContext } from "../AuthContext";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const ClientDash = () => {
    const { user } = useContext(AuthContext);
    const [mobilePickups, setMobilePickups] = useState([]);
    const [leadsGenerated, setLeadsGenerated] = useState([]);
    const [leadCount, setLeadCount] = useState(0);
    const [mobilePickupCount, setMobilePickupCount] = useState(0);
    const [dateRange, setDateRange] = useState([dayjs().subtract(30, 'day'), dayjs()]);
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [loadingClients, setLoadingClients] = useState(true);
    const [loadingDashboard, setLoadingDashboard] = useState(false);

    useEffect(() => {
        // First call to fetch clients
        setLoadingClients(true);
        axiosInstance.get("https://pbj.digital/clients").then((response) => {
            setClients(response.data);
            setLoadingClients(false);
            if (user.companyName) {
                const client = response.data.find(client => client.companyName === user.companyName);
                if (client) {
                    setClientId(client.id);
                }
            }
        }).catch(() => {
            setLoadingClients(false);
        });
    }, [user.companyName]);

    useEffect(() => {
        if (clientId) {
            setLoadingDashboard(true);
            // Second call to fetch lists and filter by clientId
            axiosInstance.get("https://pbj.digital/get-lists").then((response) => {
                const lists = response.data;
                const matchedLists = lists.filter(list => list.clientId === clientId);
                const matchedListIds = matchedLists.map(list => list.ListID);

                if (dateRange && dateRange.length === 2) {
                    axiosInstance.get(`/dashboard?clientId=${user.companyId}`).then((response) => {
                        console.log(response.data);
                        const filteredData = response.data.filter(item => {
                            const itemDate = dayjs(item.created_at); // Assuming `item.date` holds the date value
                            return matchedListIds.includes(item.matchBoxId) &&
                                ['Meeting Booked', 'Send Information', 'Not Me', 'Not Interested', 'Follow Up', 'Retired', 'Left Company', 'Wrong Number', 'Do Not Call', 'Hung Up'].includes(item.disposition) &&
                                itemDate.isAfter(dateRange[0].startOf('day')) && itemDate.isBefore(dateRange[1].endOf('day'));
                        });
                        setMobilePickups(filteredData.reverse());
                        setMobilePickupCount(filteredData.length);
                        console.log(filteredData);
                        const filteredData2 = response.data.filter(item => {
                            const itemDate = dayjs(item.created_at); // Assuming `item.date` holds the date value
                            return matchedListIds.includes(item.matchBoxId) &&
                                ['Meeting Booked', 'Send Information', 'Follow Up'].includes(item.disposition) &&
                                itemDate.isAfter(dateRange[0].startOf('day')) && itemDate.isBefore(dateRange[1].endOf('day'));
                        });
                        setLeadsGenerated(filteredData2.reverse());
                        setLeadCount(filteredData2.length);
                        setLoadingDashboard(false);
                    }).catch(() => {
                        setLoadingDashboard(false);
                    });
                } else if (!dateRange) {
                    // If the range is cleared, reset the data
                    setMobilePickups([]);
                    setMobilePickupCount(0);
                    setLeadsGenerated([]);
                    setLeadCount(0);
                    setLoadingDashboard(false);
                }
            }).catch(() => {
                setLoadingDashboard(false);
            });
        }
    }, [clientId, dateRange]);

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Disposition',
            dataIndex: 'disposition',
            key: 'disposition',
        },
        {
            title: 'Listen',
            key: 'recording_url',
            fixed: 'right',
            render: ( record) => (
                <a href={record.recording_url} target="_blank" rel="noreferrer">Listen</a>
            )
        }
    ];

    const handleCopyLink = () => {
        if (clientId) {
            const encryptedClientId = CryptoJS.AES.encrypt(clientId.toString(), 'secret-key').toString();
            const link = `https://report.pbj.digital?clientId=${encodeURIComponent(encryptedClientId)}`;
            navigator.clipboard.writeText(link).then(() => {
                message.success('Link copied to clipboard!');
            }).catch(() => {
                message.error('Failed to copy link to clipboard.');
            });
        }
    };
    

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header
            style={{
                background: 'none',
                padding: '0 16px',
                paddingTop: '0px',
                height: '100%',
              }}
              > 
                <Space direction="vertical">
                
                <Row gutter={[16,16]}>
                    <Col span={24}>
                    <Row gutter={[16,16]}>
                        <Col span={8}>
                        <Logo />
                        </Col>
                        <Col span={8}>
                            {loadingClients ? (
                                <Spin />
                            ) : (
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select Client"
                                    value={clientId}
                                    onChange={(value) => setClientId(value)}
                                >
                                    {clients.map(client => (
                                        <Option key={client.id} value={client.id}>{client.companyName}</Option>
                                    ))}
                                </Select>
                            )}
                        </Col>
                        <Col span={8}>
                        <RangePicker 
                            defaultValue={[dayjs().subtract(30, 'day'), dayjs()]} 
                            onChange={(dates) => setDateRange(dates)}
                            allowClear
                        />
                        </Col>
                    </Row>
                    </Col>
                    <Col span={24}>
                        {clientId && (
                            <Button type="primary" style={{ marginTop: 20 }} onClick={handleCopyLink}>
                                Copy Report Link to Clipboard
                            </Button>
                        )}
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                {loadingDashboard ? (
                                    <Spin />
                                ) : (
                                    <Table dataSource={mobilePickups} columns={columns} rowKey="id" style={{ marginTop: 20 }} />
                                )}
                            </Col>
                            <Col span={24}>
                                {loadingDashboard ? (
                                    <Spin />
                                ) : (
                                    <Table dataSource={leadsGenerated} columns={columns} rowKey="id" style={{ marginTop: 20 }} />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <Card >
                                    {loadingDashboard ? (
                                        <Spin />
                                    ) : (
                                        <Space direction="vertical">
                                            <Statistic title="Mobile Pickups" value={mobilePickupCount} />
                                            <Statistic title="Leads Generated" value={leadCount} />
                                        </Space>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                    </Col>
                    <Col span={12}>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                </Space>
            </Header>
        </Layout>
    );
}
