// index.js
import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import process from 'process';

// Option 1: Import your local logo
// Ensure you have a logo.png in your src directory
import logo from './logo.webp';
import axios from 'axios';
import { AuthProvider } from './AuthContext';
import { jwtDecode } from 'jwt-decode';
import { message } from 'antd';
window.process = process;

// Option 2: Use an external logo URL
// Uncomment the following line and comment out the local logo import if you prefer using an external image
// const logo = 'https://via.placeholder.com/150'; // Replace with your logo URL
const getRandomSentence = () => {
  const sentences = [
    'Ignite Your Sales with Every Call.',
    'Spark Conversations, Light Up Success.',
    'Strike a Match, Start a Sale.',
    'Light the Fire of Opportunity.',
    'Fuel Your Growth, One Call at a Time.',
    'Where Every Call Sparks Potential.',
    'Igniting Connections.',
    'Fire Up Your Sales Pipeline.',
    'Sparking Success with Every Ring.',
    'Kindle Your Business with Our Dialer.',
    'Turn Cold Calls into Hot Leads.',
    'Light the Path to Your Next Sale.',
    'Ignite Your Outreach Efforts.',
    'Burn Through Your Sales Targets.',
    'Spark Interest, Fuel Success.',
    'The Spark for Your Sales.',
    'Light Up Your Customer Base.',
    'Where Every Call Starts a Flame.',
    'Set Your Sales Ablaze.',
    'Lighting the Way to Sales.',
    'Fan the Flames of Your Next Deal.',
    'Turn Up the Heat on Your Sales.',
    'A Firestorm of Sales Awaits.',
    'Brighten Your Sales with Every Call.',
    'Stoke the Flames of Opportunity.',
    'Heat Up Your Sales Pipeline.',
    'Torch the Competition, One Call at a Time.',
    'Ignite Profits with Every Conversation.',
    'Where Every Call is a Flame.',
    'Accelerate Growth, One Spark at a Time.',
    'Stoke Your Sales to Success.',
    'Burn Brighter with Every Dial.',
    'Start a Spark, Close a Deal.',
    'Lighting a Path to Sales Growth.',
    'Light Up Every Opportunity.',
    'Blaze New Trails in Your Sales Journey.',
    'Where Every Ring Sparks Results.',
    'Set the Market on Fire with Your Pitch.',
    'Create a Blaze of Activity in Your Pipeline.',
    'Burn the Midnight Oil for More Sales.',
    'Stoke the Coals of Customer Interest.',
    'Fire Up Conversations, Fuel Your Success.',
    'Set Your Sales Strategy Alight.',
    'Fan the Flames of Customer Engagement.',
    'Spark More Leads with Every Call.',
    'Fire Up Every Opportunity.',
    'Brighten Your Business with Hot Leads.',
    'Light Up Your Outreach with Purpose.',
    'Turn a Flicker into a Sales Inferno.',
    'Set the Stage for Success with Every Call.',
    'Heat Up Conversations, Close More Deals.',
    'Strike Gold with Every Call Spark.',
    'Warm Up Cold Leads to Hot Sales.',
    'Your Calls, a Beacon for Sales.',
    'Light a Fire Under Your Sales Strategy.',
    'Where Every Dial Sparks New Possibilities.',
    'Stoke the Sales Engine with Every Call.',
    'Set the Phone Lines on Fire.',
    'Heat Up the Competition with Our Dialer.',
    'Spark Conversations, Fuel Growth.',
    'Strike First, Close Fast.',
    'Ignite the Sales Revolution.',
    'Light the Fire of Customer Curiosity.',
    'Fan the Flames of a Bright Future.',
    'Burn Bright with Every Sales Opportunity.',
    'Spark the Right Conversation at the Right Time.',
    'Fan the Flames of Demand.',
    'Kindle New Relationships with Every Call.',
    'Fire Up the Engine of Opportunity.',
    'Burn the Competition with Your Sales Tactics.',
    'Set Every Call on Fire.',
    'Your Sales, Our Spark.',
    'Where Every Call Lights Up a Deal.',
    'Blaze Through Your Targets.',
    'Turn the Heat Up on Your Sales Game.',
    'Fire Up the Sales Engine.',
    'Light the Fuse of Opportunity.',
    'Light Up Your Pipeline with Potential.',
    'Kindle Conversations, Fuel Growth.',
    'Strike Fire with Every Call.',
    'Burn Bright in the Marketplace.',
    'Light the Path to New Sales.',
    'Heat Up Every Interaction.',
    'Ignite the Sales Conversation.',
    'Stoke the Flames of Potential.',
    'Spark Change, Fuel Profits.',
    'Burn Through Cold Leads to Find Gold.',
    'Light the Way for Your Sales Team.',
    'Ignite Your Customers\' Interest.',
    'Set Your Sales on Fire.',
    'Spark Innovation, Close More Deals.',
    'Turn the Flames of Interest into Action.',
    'Fan the Flames of a Hot Lead.',
    'Ignite a Revolution in Cold Calling.',
    'Kindle Interest with Every Ring.',
    'Stoke the Fire of Customer Engagement.',
    'Fire Up the Phones and Fuel Success.',
    'Where Every Spark Leads to Success.',
    'Light the Match of Opportunity.',
    'Blaze New Trails, Achieve New Heights.'
];
  const randomIndex = Math.floor(Math.random() * sentences.length);
  return sentences[randomIndex];
};

// LoginScreen Component
const LoginScreen = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://pbj.digital/api/login', {
        username,
        password,
      });

      const { token } = response.data;

      // Store the token in localStorage
      localStorage.setItem('authToken', token);
      window.location.reload();
      // Notify the parent component of successful login
      onLogin();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };

  return (
    <>
      {/* Inline CSS Styles for LoginScreen */}
      <style>
        {`
          .login-screen {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100vh;
            background-color: ${localStorage.getItem('isDarkMode') === 'true' ? '#001529' : '#f5f5f5'};
          }

          .login-form {
            background-color: ${localStorage.getItem('isDarkMode') === 'true' ? '#00203FFF' : '#ffffff'};
            padding: 40px;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1);
            width: 300px;
          }

          .login-form h2 {
            margin-bottom: 20px;
            color: ${localStorage.getItem('isDarkMode') === 'true' ? '#ffffff' : '#333333'};
            text-align: center;
          }

          .login-form input {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 1rem;
          }

          .login-form button {
            width: 100%;
            padding: 10px;
            background-color: #00bcd4;
            border: none;
            border-radius: 4px;
            color: #ffffff;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          .login-form button:hover {
            background-color: #0097a7;
          }

          .error-message {
            color: red;
            text-align: center;
            margin-bottom: 10px;
          }

          /* Responsive Design */
          @media (max-width: 400px) {
            .login-form {
              width: 90%;
              padding: 20px;
            }
          }
        `}
      </style>

      <div className="login-screen">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Login</h2>
          {error && <div className="error-message">{error}</div>}
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            autoComplete="username"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </>
  );
};


const LoadingScreen = ({ onFinish }) => {
  const [phase, setPhase] = useState('showMatchstick'); // 'showMatchstick', 'rollOut', 'showLight', 'fadeOut'

  useEffect(() => {
    // Phase 1: Show "Matchstick" for 1 second
    const showMatchstickTimer = setTimeout(() => {
      setPhase('rollOut');
    }, 1000); // 1 second

    // Phase 2: Roll out "Matchstick" over 0.5 seconds
    const rollOutTimer = setTimeout(() => {
      setPhase('showLight');
    }, 2000); // 1.5 seconds total

    // Phase 3: Start fade out after showing "Light That Match"
    const fadeOutTimer = setTimeout(() => {
      setPhase('fadeOut');
    }, 4500); // 3.5 seconds total

    // Phase 4: Finish loading after fade out
    const finishTimer = setTimeout(() => {
      if (onFinish) onFinish();
    }, 5100); // 4 seconds total

    // Cleanup timers on unmount
    return () => {
      clearTimeout(showMatchstickTimer);
      clearTimeout(rollOutTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(finishTimer);
    };
  }, [onFinish]);

  // Determine which text to display based on the current phase
  const isRollingOut = phase === 'rollOut';
  const isShowingLight = phase === 'showLight';
  const isFadingOut = phase === 'fadeOut';

  return (
    <>
      {/* Inline CSS Styles */}
      <style>
        {`
          .loading-screen-wrapper {
            position: fixed; /* Cover the entire viewport */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${ localStorage.getItem('isDarkMode') == 'true' ?'rgba(0,21, 41, 1)':'rgba(255, 255, 255, 1)'}; /* Semi-transparent background */
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 9999; /* Ensure it's on top */
            transition: opacity 0.5s ease, visibility 0.5s ease;
          }

          .loading-screen-wrapper.fade-out {
            opacity: 0;
            visibility: hidden;
          }

          .loading-logo {
            width: 150px; /* Adjust size as needed */
            height: 150px;
            margin-bottom: 20px;
          }

          .animated-text {
            display: flex;
            font-size: 2rem;
            color: #00bcd4;
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-style: italic;
          letter-pacing: 0.5px;
            perspective: 1000px;
          }

          /* Initial state for "Matchstick" */
          .matchstick span {
            display: inline-block;
            opacity: 1;
            transform: rotateX(0deg);
          }

          /* Rolling Out Animation */
          .rolling-out {
            animation: rollOut 0.5s forwards;
          }

          @keyframes rollOut {
            to {
              opacity: 0;
              transform: rotateX(-90deg);
            }
          }

          /* Initial state for "Light That Match" */
          .light-that-match span {
            display: inline-block;
            opacity: 0;
            transform: rotateX(90deg);
          }

          /* Rolling In Animation */
          .rolling-in {
            animation: rollIn 0.5s forwards;
          }

          @keyframes rollIn {
            to {
              opacity: 1;
              transform: rotateX(0deg);
            }
          }

          /* Fade Out Animation */
          .fade-out-animation {
            animation: fadeOut 0.5s forwards;
          }

          @keyframes fadeOut {
            to {
              opacity: 0;
              visibility: hidden;
            }
          }
        `}
      </style>

      <div className={`loading-screen-wrapper ${phase === 'fadeOut' ? 'fade-out' : ''}`}>
        {/* Logo */}
        <img src={logo} alt="Logo" className="loading-logo" />

        {/* "Matchstick" Text */}
        { (phase === 'showMatchstick' || phase === 'rollOut') && (
          <div className="animated-text matchstick">
            {Array.from('Matchstick').map((char, index) => (
              <span
                key={`matchstick-${index}`}
                className={isRollingOut ? 'rolling-out' : ''}
                style={isRollingOut ? { animationDelay: `${index * 0.05}s` } : {}}
              >
                {char}
              </span>
            ))}
          </div>
        )}
        
        {/* "Light That Match" Text */}
        { (phase === 'showLight' || phase === 'fadeOut') && (
          <div className="animated-text light-that-match">
            {Array.from(getRandomSentence()).map((char, index) => (
              char === ' ' ? (
                <span key={`light-space-${index}`}>&nbsp;</span>
              ) : (
                <span
                  key={`light-${index}`}
                  className={isShowingLight ? 'rolling-in' : ''}
                  style={isShowingLight ? { animationDelay: `${index * 0.05}s` } : {}}
                >
                  {char}
                </span>
              )
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const Root = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleFinishLoading = () => {
    setIsLoading(false);
    console.log('fire loading')
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };
  useEffect(() => {
    if (localStorage.getItem('authToken') && jwtDecode(localStorage.getItem('authToken')).exp < Date.now() / 1000 && localStorage.removeItem('authToken'));
    setIsLoggedIn(localStorage.getItem('authToken') ? true : false);
    if (isLoggedIn) {
      setIsLoading(false);
      message.success('Logged in successfully');
    }else{
    }
  }, []);

  return (
    <>
      {!isLoggedIn ? <LoginScreen onLogin={handleLogin} /> : <><App /> <LoadingScreen  /></>}
      {/* <App /> */}
      {/* Render LoadingScreen if isLoading is true */}
      {/* {isLoading && <LoadingScreen onFinish={handleFinishLoading} />} */}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
  <Root />
  </AuthProvider>
);

reportWebVitals();