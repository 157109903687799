import React, { useRef, useState, useContext } from 'react';
import './NotebookTextEditor.css';
// If using Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faSave } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../AuthContext';
import axiosInstance from './axiosConfig';

const NotebookTextEditor = ({isDarkMode, answeredCaller}) => {
  const { user } = useContext(AuthContext);
  const editorRef = useRef(null);
  const [status, setStatus] = useState('');

  const formatText = (command) => {
    document.execCommand(command, false, null);
    editorRef.current.focus();
  };

  const handleSave = async () => {
    const formattedText = editorRef.current.innerHTML;

    // Optional: Validate or sanitize the HTML here

    if (formattedText.length > 1000) {
      setStatus('Error: Content exceeds 1000 characters.');
      return;
    }

    try {
      const response = await axiosInstance.post('/save-note/', 
       { text: formattedText, id: parseInt(answeredCaller), companyId: user.companyId });

      if (response.ok) {
        setStatus('Content saved successfully!');
      } else {
        // setStatus('Error saving content.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('Error saving content.');
    }
  };

  return (
    <div className="notebook-editor-container">
     <div className="toolbar">
        <button onClick={() => formatText('bold')} title="Bold">
          <FontAwesomeIcon icon={faBold} color={isDarkMode ? "white": "black"}/>
        </button>
        <button onClick={() => formatText('italic')} title="Italic">
          <FontAwesomeIcon icon={faItalic} color={isDarkMode ? "white": "black"}/>
        </button>
        <button onClick={() => formatText('underline')} style={{backgroundColor: ""}} title="Underline">
          <FontAwesomeIcon icon={faUnderline} color={isDarkMode ? "white": "black"}/>
        </button>
        {/* Add more formatting buttons as needed */}
        <button onClick={handleSave} title="Save">
          <FontAwesomeIcon icon={faSave}  color={isDarkMode ? "white": "black"}/>
        </button>
      </div>
      <div
        ref={editorRef}
        id="NoteEditor"
        className="notebook-textarea"
        contentEditable
        suppressContentEditableWarning={true}
        spellCheck={false}
      >
      </div>
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default NotebookTextEditor;
