import React, { useState } from 'react';
import { Modal, Form, Input, Upload, Button, message, Select, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import axios from 'axios';
import { Option } from 'antd/es/mentions';
import { normalizePhoneNumber } from './phoneUtils';
import axiosInstance from './axiosConfig';

const authToken = localStorage.getItem('authToken');


// Common field mappings for auto-detection
const fieldMappings = {
  first_name: [
    'first', 'first_name', 'fname', 'f_name', 'firstname', 'given_name', 'name_first', 
    'f_name_initial', 'f_initial'
  ],
  last_name: [
    'last', 'last_name', 'lname', 'surname', 'family_name', 'name_last', 'surname_last',
    'l_name', 'lastname'
  ],
  email: [
    'email', 'email_address', 'mail', 'e_mail', 'emailaddr', 'e_address', 'contact_email', 
    'personal_email', 'business_email', 'work_email', 'primary_email'
  ],
  kixie_phone_number: [
    'phone', 'phone_number', 'mobile', 'cell', 'contact_number', 'telephone', 'telephone_number', 
    'tel', 'mobile_phone', 'cell_phone', 'phone_num', 'mobile_num', 'mobile_number', 
    'contact_phone', 'primary_phone', 'work_phone', 'home_phone'
  ],
  company: [
    'company', 'company_name', 'organization', 'org', 'business_name', 'business', 'employer',
    'workplace', 'corp', 'corporation', 'firm', 'company_title', 'company_org'
  ],
  title: [
    'title', 'job_title', 'position', 'role', 'designation', 'job', 'job_position', 'employment_title',
    'work_title', 'official_title', 'position_title', 'current_title'
  ]
};

const CsvUploadModalComponent = ({setMatchboxList}) => {
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [uploadForm] = Form.useForm();
  const [csvData, setCsvData] = useState(null); // Store parsed CSV data
  const [headers, setHeaders] = useState([]);   // Store CSV headers
  const [mappedFields, setMappedFields] = useState({
    first_name: '',
    last_name: '',
    email: '',
    kixie_phone_number: '',
    company: '',
    title: '',
    custom_field_1: '',
    custom_field_2: '',
    custom_field_3: '',
    custom_field_4: '',
    custom_field_5: ''
  });

  // Function to auto-detect and map fields based on CSV headers
  const autoMapFields = (headers) => {
    const autoMapping = { ...mappedFields };

    headers.forEach(header => {
      const normalizedHeader = header.toLowerCase().replace(/\s+/g, '_');

      // Match CSV headers to the standard field names
      for (const [field, variations] of Object.entries(fieldMappings)) {
        if (variations.includes(normalizedHeader)) {
          autoMapping[field] = header; // Auto-map detected field
        }
      }
    });

    setMappedFields(autoMapping); // Set the auto-mapped fields
  };

  // Handle CSV Upload using PapaParse (only parsing locally)
  const handleUpload = ({ file }) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setCsvData(result.data); // Store CSV data locally
        const csvHeaders = Object.keys(result.data[0]); // Extract headers
        setHeaders(csvHeaders); // Store CSV headers
        autoMapFields(csvHeaders); // Auto-map the fields based on detected headers
        message.success(`${file.name} uploaded successfully.`);
      },
      error: () => {
        message.error(`${file.name} upload failed.`);
      }
    });
  };

  // Handle field mapping change (allow user to adjust auto-mapping)
  const handleMappingChange = (value, name) => {
    setMappedFields({
      ...mappedFields,
      [name]: value
    });
  };

  // Convert JSON to CSV string
  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return new Blob([csv], { type: 'text/csv' });
  };

  // First, create a list using the listName and optional listID
  const createList = (listID, listName) => {
    return axiosInstance.post('/create-list', { listID, listName })
      .then(response => {
        const createdListID = response.data.listID;
        return createdListID;
      })
      .catch(error => {
        message.error('Error creating list');
        console.error('Error creating list:', error);
        throw error; // Propagate error to stop CSV upload if list creation fails
      });
  };

  // Finalize the process and send formatted data to the previous endpoint
  const processMappedData = () => {
    uploadForm.validateFields().then(values => {
      const { listID, listName } = values;

      if (!csvData) {
        message.error('No CSV data available. Please upload and map the CSV file.');
        return;
      }

      // Validate that the required fields are mapped
      if (!mappedFields.kixie_phone_number) {
        message.error('Please map the Phone field.');
        return;
      }

      // Map the CSV data based on user-selected fields
      const mappedData = csvData.map((row) => ({
        'First name': row[mappedFields.first_name],
        'Last name': row[mappedFields.last_name],
        Email: row[mappedFields.email],
        Phone: normalizePhoneNumber(row[mappedFields.kixie_phone_number]),
        Company: row[mappedFields.company],
        Title: row[mappedFields.title],
        'Custom Field 1': row[mappedFields.custom_field_1] || '',
        'Custom Field 2': row[mappedFields.custom_field_2] || '',
        'Custom Field 3': row[mappedFields.custom_field_3] || '',
        'Custom Field 4': row[mappedFields.custom_field_4] || '',
        'Custom Field 5': row[mappedFields.custom_field_5] || ''
      }));

      // Step 1: Create the list
      createList(listID, listName)
        .then(createdListID => {
          // Step 2: After creating the list, convert the mapped data to a CSV
          const csvBlob = convertToCSV(mappedData);

          // Step 3: Send the CSV along with the createdListID
          const formData = new FormData();
          formData.append('file', csvBlob, 'mapped_data.csv');
          formData.append('listID', createdListID); // Add the created list ID to the form data

          // Step 4: Send the mapped CSV file to the same endpoint as before
          return axiosInstance.post('/upload-csv', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        })
        .then(() => {
          axiosInstance.get('https://pbj.digital/get-lists', authToken).then((data)=>{console.log(data); setMatchboxList(data.data)});
          message.success('Data uploaded successfully.');
          setIsUploadModalVisible(false); // Close modal on success

        })
        .catch(error => {
          console.error('Upload failed:', error);
          message.error('Upload failed.');
        });
    });
  };

  // Handle opening the modal
  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  // Handle cancel/close modal
  const handleUploadCancel = () => {
    setIsUploadModalVisible(false);
  };

  const generateColumns = () => {
    return headers.map(header => ({
      title: header,
      dataIndex: header,
      key: header,
    }));
  };

  return (
    <>
      {/* Button to trigger the modal */}
      <Button type="primary" onClick={showUploadModal}>
        Upload CSV
      </Button>

      {/* Modal component */}
      <Modal
        title="Create New List and Upload CSV"
        visible={isUploadModalVisible}
        onOk={processMappedData}  // Process data and upload on "OK" click
        onCancel={handleUploadCancel}
        width='70%'
      > 
        {/* Upload form */}
        <Form form={uploadForm} layout="vertical">
          <Form.Item
            name="listName"
            label="List Name"
            rules={[{ required: true, message: 'Please input the list name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="file"
            label="CSV File"
            rules={[{ required: true, message: 'Please upload a CSV file!' }]}
          >
            <Upload customRequest={handleUpload} showUploadList={false}>
              <Button icon={<UploadOutlined />}>Upload CSV</Button>
            </Upload>
          </Form.Item>
        </Form>
        {/* CSV Viewer */}
        {csvData && headers.length > 0 && (
          <div>
            <h3 style={{ marginBottom: '20px', fontSize: '18px' }}>Unmapped CSV Data</h3>
            <Table 
              columns={generateColumns()} 
              dataSource={csvData} 
              rowKey={(record, index) => index} 
              scroll={{ x: 'max-content' }} 
              pagination={{ pageSize: 5 }} 
            />
          </div>
        )}
        {/* Show field mapping options only after the CSV is uploaded */}
        {headers.length > 0 && (
          <div>
            <h3 style={{ marginBottom: '20px', fontSize: '18px' }}>Map Your Fields</h3>

            {/* First Name */}
            <Form.Item label="First Name" style={{ marginBottom: '16px' }}>
              <Select
                placeholder="Select Field"
                name="first_name"
                value={mappedFields.first_name}
                onChange={(value) => handleMappingChange(value, 'first_name')}
              >
                {headers.map((header) => (
                  <Option key={header} value={header}>
                    {header}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Last Name */}
            <Form.Item label="Last Name" style={{ marginBottom: '16px' }}>
              <Select
                placeholder="Select Field"
                name="last_name"
                value={mappedFields.last_name}
                onChange={(value) => handleMappingChange(value, 'last_name')}
              >
                {headers.map((header) => (
                  <Option key={header} value={header}>
                    {header}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Email */}
            <Form.Item label="Email" style={{ marginBottom: '16px' }}>
              <Select
                placeholder="Select Field"
                name="email"
                value={mappedFields.email}
                onChange={(value) => handleMappingChange(value, 'email')}
              >
                {headers.map((header) => (
                  <Option key={header} value={header}>
                    {header}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Phone */}
            <Form.Item label="Phone" required style={{ marginBottom: '16px' }}>
              <Select
                placeholder="Select Field"
                name="kixie_phone_number"
                value={mappedFields.kixie_phone_number}
                onChange={(value) => handleMappingChange(value, 'kixie_phone_number')}
              >
                {headers.map((header) => (
                  <Option key={header} value={header}>
                    {header}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Title */}
            <Form.Item label="Title" required style={{ marginBottom: '16px' }}>
              <Select
                placeholder="Select Field"
                name="title"
                value={mappedFields.title}
                onChange={(value) => handleMappingChange(value, 'title')}
              >
                {headers.map((header) => (
                  <Option key={header} value={header}>
                    {header}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Company */}
            <Form.Item label="Company" required style={{ marginBottom: '16px' }}>
              <Select
                placeholder="Select Field"
                name="company"
                value={mappedFields.company}
                onChange={(value) => handleMappingChange(value, 'company')}
              >
                {headers.map((header) => (
                  <Option key={header} value={header}>
                    {header}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Custom Fields 1-5 */}
            {[1, 2, 3, 4, 5].map((i) => (
              <Form.Item label={`Custom Field ${i}`} key={`custom_field_${i}`} style={{ marginBottom: '16px' }}>
                <Select
                  placeholder={`Select Custom Field ${i}`}
                  name={`custom_field_${i}`}
                  value={mappedFields[`custom_field_${i}`]}
                  onChange={(value) => handleMappingChange(value, `custom_field_${i}`)}
                >
                  {headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
};

export default CsvUploadModalComponent;