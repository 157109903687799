// PlivoWebphone Component (assuming changes to incorporate the modal)

import React, { useEffect, useState } from 'react';
import { Modal, Button, Tooltip, Input, Card, Row, Col, Space } from 'antd';
import { InfoCircleOutlined, SettingOutlined, PhoneOutlined, StopOutlined, SoundOutlined, PauseOutlined, PauseCircleOutlined, StepForwardOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import 'intl-tel-input/build/css/intlTelInput.css';
import { handleHangup, handleMakeCall, initPhone, onLogin, setIti, trimSpace, plivoBrowserSdk, onCallAnswered, handleNoAnswerHangup, handleBadDataHangup } from '../js/customclient.js';
import { filterList } from '../App.js';
export const pushNumbersToEndpoint = async (numbers) => {
  try {
    await axios.post('https://layerloom.com/set-numbers', { numbers });
    console.log('Numbers pushed successfully', numbers);
  } catch (error) {
    console.error('Error pushing numbers:', error);
  }
};

export const startCalls = async () => {
  localStorage.removeItem('lastReported');
  console.log("calls started");
  document.getElementById('dispositionDropdown').value = null;
  //console.log("dispositionDropdown", document.getElementById('dispositionDropdown').value);
  let numbers = JSON.parse(localStorage.getItem('selectedMatchbox')).items;
  console.log('All contacts:', numbers);
  if (numbers.length < 3 && !document.querySelector("#toNumber").value) {
    console.error('Not enough contacts to make 3 calls');
    return;
  }

  const selectedNumbers = document.querySelector("#toNumber").value ? [document.querySelector("#toNumber").value, document.querySelector("#toNumber").value, document.querySelector("#toNumber").value] : numbers.slice(0, 3).map(contact => contact.kixie_phone_number);
  console.log('Selected numbers:', selectedNumbers);

  await pushNumbersToEndpoint(selectedNumbers).then(() => {
    handleMakeCall(document.querySelector("#toNumber").value);
  });
  document.getElementById('answeredContactName').innerHTML = "";
              document.getElementById('answeredContactCompany').innerHTML = "";
              document.getElementById('answeredContactTitle').innerHTML = "";
              document.getElementById('answeredContactEmail').innerHTML = "";
              document.getElementById('answeredContactNumber').innerHTML = "";
              document.getElementById('answeredContactCustom1').innerHTML = "";
              document.getElementById('answeredContactCustom2').innerHTML = "";
              document.getElementById('answeredContactCustom3').innerHTML = "";
              document.getElementById('answeredContactCustom4').innerHTML = "";
              document.getElementById('answeredContactCustom5').innerHTML = "";
              document.getElementById('answeredContactId').innerHTML = "";
              document.getElementById('NoteEditor').innerHTML = "";
              //document.getElementById('Summary').innerHTML = "";
};

const Keypad = () => {
  const handleKeypadClick = (value) => {
    // Handle keypad button click
    console.log('Keypad button clicked:', value);
  };

  return (
    <div className="keypad">
      <Row>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('1')}>1</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('2')}>2</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('3')}>3</Button>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('4')}>4</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('5')}>5</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('6')}>6</Button>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('7')}>7</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('8')}>8</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('9')}>9</Button>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('*')}>*</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('0')}>0</Button>
        </Col>
        <Col span={8}>
          <Button onClick={() => handleKeypadClick('#')}>#</Button>
        </Col>
      </Row>
    </div>
  );
};

export const PlivoWebphone = ({ answeredContact, setShouldRefetch, setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint }) => {
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showAudioDevicesModal, setShowAudioDevicesModal] = useState(false);
  const [currentCaller, setCurrentCaller] = useState(null);
  const [currentCallInfo, setCurrentCallInfo] = useState(null);
  const [showMatchedModal, setShowMatchedModal] = useState(false);
  const [matchedContact, setMatchedContact] = useState(null);
  

  useEffect(() => {
    initPhone(answeredContact,  setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint);
    const input = document.querySelector("#toNumber");
    const iti = window.intlTelInput(input, {});
    setIti(iti);
    const username = 'IAD4361733043699272042';
    const password = 'BrandonDoesCode@23';
    onLogin(username, password);
  }, []);





  const handleLoginClick = () => {
    const username = document.getElementById('loginUser').value;
    const password = document.getElementById('loginPwd').value;
    onLogin(username, password);
  };



  const onAnsweredCall = (callInfo) => {
    onCallAnswered(callInfo, answeredContact);
    const matched = localStorage.getItem('selectedMatchbox').items.find(contact => contact.kixie_phone_number === callInfo.src);
    setMatchedContact(matched);
    localStorage.setItem('answeredNumber', matched.kixie_phone_number);
    setShowMatchedModal(true);
  };

  return (
    <>
      <div id="loginContainer" className="text-center" style={{ marginTop: '7%', height: '100%' }}>
        <h2>Log In to your Plivo account</h2>
        <Row justify="end">
          <Col>
            <Tooltip title="Config">
              <SettingOutlined onClick={() => setShowConfigModal(true)} style={{ fontSize: '24px', cursor: 'pointer' }} />
            </Tooltip>
          </Col>
        </Row>
        <form>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <label htmlFor="loginUser">USERNAME</label>
            </Col>
            <Col span={18}>
              <Input
                type="text"
                onBlur={(e) => trimSpace(e.target)}
                id="loginUser"
                placeholder="plivo userId"
                autoComplete="username"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <label htmlFor="loginPwd">PASSWORD</label>
            </Col>
            <Col span={18}>
              <Input.Password
                id="loginPwd"
                placeholder="password"
                autoComplete="current-password"
              />
            </Col>
          </Row>
        </form>
        <Button type="primary" onClick={handleLoginClick} id="clickLogin">Login</Button>
      </div>

      {/* Configuration Modal */}
      <Modal
        title="OPTIONS FOR INITIALIZING THE PLIVO WEBSDK OBJECT"
        visible={showConfigModal}
        onCancel={() => setShowConfigModal(false)}
        footer={[
          <Button key="reset" onClick={() => setShowConfigModal(false)}>Reset</Button>,
          <Button key="update" type="primary" onClick={() => setShowConfigModal(false)}>Update</Button>,
        ]}
      >
        <form>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <label htmlFor="loglevel">LOG LEVEL</label>
              <Tooltip title="Enable log level in JS log">
                <InfoCircleOutlined style={{ marginLeft: '8px' }} />
              </Tooltip>
            </Col>
            <Col span={18}>
              <select className="ant-select" name="loglevelbtn" id="loglevelbtn">
                <option value="INFO">INFO</option>
                <option value="OFF">OFF</option>
                <option value="ERROR">ERROR</option>
                <option value="WARN">WARN</option>
                <option value="DEBUG">DEBUG</option>
                <option value="ALL">ALL</option>
              </select>
            </Col>
          </Row>
        </form>
      </Modal>

      {/* Audio Devices Modal */}
      <Modal
        title="AUDIO DEVICE SETTINGS"
        visible={showAudioDevicesModal}
        onCancel={() => setShowAudioDevicesModal(false)}
        footer={null}
      >
        <form>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <label htmlFor="micDev">MICROPHONE</label>
            </Col>
            <Col span={18}>
              <select className="ant-select" id="micDev"></select>
            </Col>
          </Row>
        </form>
      </Modal>

      {/* Call Container */}
      <div id="callContainer" style={{ display: 'none' }}>

        <div className="phone" id="phone">
          <Input type="tel" id="toNumber" placeholder="Enter phone number" />
          <button id="mainCallButton" onClick={startCalls}>DIAL NEXT</button>
          <Button type="link" onClick={() => setShowAudioDevicesModal(true)}>MIC/SPEAKER SETTINGS</Button>
        </div>
        <div className="callinfo">
          <Space direction="horizontal">
            <Keypad />
            <button id="hangupButton" onClick={() =>{handleHangup(setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint)}} hidden="true"  ><PauseOutlined /> HANGUP</button>
            <button danger id="noAnswer" style={{ backgroundColor: "orange", color: "white", border: "0px", borderRadius: "5px", padding: "5px 20px" }} hidden="true" onClick={()=>{handleNoAnswerHangup(setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint)}} > <StepForwardOutlined /> NO ANSWER</button>
            <button danger id="badData" style={{ backgroundColor: "gray", color: "white", border: "0px", borderRadius: "5px", padding: "5px 20px" }} hidden="true" onClick={()=>{handleBadDataHangup(setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint)}} ><DeleteOutlined /><StepForwardOutlined /> REMOVE</button>
          </Space>
        </div>
      </div>

      {/* Matched Caller Modal */}
      <Modal
        title="Caller Information"
        visible={showMatchedModal}
        onCancel={() => setShowMatchedModal(false)}
        footer={[
          <Button key="close" type="primary" onClick={() => setShowMatchedModal(false)}>
            Close
          </Button>,
        ]}
      >
        {matchedContact ? (
          <div>
            <p><strong>Caller: </strong> {matchedContact.name}</p>
            <p><strong>Phone Number: </strong> {matchedContact.kixie_phone_number}</p>
            {/* Add any other relevant info */}
          </div>
        ) : (
          <p>No matched contact information found.</p>
        )}
      </Modal>
    </>
  );
};

export default PlivoWebphone;